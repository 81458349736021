import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >
				<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m4490 2606 c540 -62 1064 -294 1448 -641 l62 -57 0 356 0 356 415 0
415 0 0 -1315 0 -1315 -415 0 -415 0 0 89 c0 194 -80 505 -185 713 -291 582
-855 955 -1512 1003 l-93 7 0 409 0 409 78 0 c42 0 133 -7 202 -14z m-115
-1212 c254 -27 532 -150 740 -328 292 -251 467 -595 491 -968 l7 -108 -410 0
-409 0 -17 92 c-47 266 -253 464 -514 495 l-53 6 0 407 c0 224 3 410 7 414 4
4 28 5 53 2 25 -3 72 -8 105 -12z m-39 -1200 c124 -76 151 -253 57 -365 -85
-101 -269 -105 -360 -7 -64 69 -81 185 -39 274 41 89 123 136 227 131 53 -2
76 -9 115 -33z m-1692 -1229 c40 -88 86 -188 101 -223 16 -34 32 -59 36 -55 3
5 42 87 85 183 43 96 87 193 99 215 l20 40 105 0 105 0 3 -382 2 -383 -90 0
-90 0 -1 238 c0 184 -3 231 -12 212 -17 -38 -50 -113 -107 -245 -28 -66 -57
-123 -63 -127 -20 -13 -114 -9 -125 5 -8 10 -153 332 -175 387 -3 8 -6 -94 -6
-227 l-1 -243 -90 0 -90 0 0 378 c0 208 3 382 7 386 4 3 53 5 110 4 l103 -3
74 -160z m2236 85 l0 -80 -185 0 -185 0 0 -85 0 -85 168 -2 167 -3 3 -77 3
-78 -171 -2 -172 -3 -1 -135 -2 -135 -94 -3 c-69 -2 -97 1 -103 10 -9 15 -11
742 -1 751 3 4 134 7 290 7 l283 0 0 -80z m612 66 c181 -35 289 -140 318 -310
24 -133 -18 -257 -117 -345 -96 -86 -130 -94 -390 -99 l-223 -4 0 380 c0 208
3 381 6 384 13 13 331 8 406 -6z m2076 -68 l3 -78 -116 0 c-113 0 -115 0 -115
-22 0 -13 0 -150 0 -305 l1 -283 -101 0 -100 0 -2 303 -3 302 -110 2 -110 1
-3 81 -3 81 328 -2 328 -3 3 -77z m-3508 -3 l0 -75 -197 -2 -198 -3 -3 -72 -3
-73 181 0 180 0 0 -80 0 -80 -180 0 -180 0 0 -75 0 -75 205 0 205 0 0 -75 0
-75 -305 0 -305 0 -1 360 c-1 198 -2 366 -3 373 -1 6 -1 15 1 20 2 4 139 7
304 7 l299 0 0 -75z m2433 38 c89 -208 294 -696 301 -716 5 -16 -2 -17 -100
-15 l-106 3 -28 70 -28 70 -161 3 -162 2 -34 -75 -34 -75 -96 0 c-78 0 -95 3
-95 15 0 8 18 55 40 105 22 49 58 131 80 180 22 50 40 93 40 96 0 3 11 29 24
57 28 61 112 256 126 292 9 24 12 25 113 25 l104 0 16 -37z m1784 -123 c35
-85 86 -207 113 -270 27 -63 69 -164 94 -224 25 -59 46 -110 46 -112 0 -3 -48
-3 -106 -2 l-107 3 -26 70 -27 70 -162 3 -163 2 -25 -57 c-39 -94 -31 -88
-138 -91 -75 -2 -96 0 -96 11 0 7 27 76 61 152 33 77 87 203 121 280 33 77 68
158 78 180 10 22 28 64 40 93 l23 53 104 -3 104 -3 66 -155z m-5212 -885 c18
-17 25 -35 25 -64 0 -54 -34 -81 -101 -81 l-49 0 0 78 c0 43 3 82 7 85 3 4 26
7 50 7 33 0 50 -6 68 -25z m250 -51 c19 -42 35 -80 35 -85 0 -17 -29 -9 -40
11 -7 13 -21 20 -40 20 -19 0 -34 -7 -42 -20 -12 -19 -38 -28 -38 -12 0 23 66
162 77 162 7 0 29 -34 48 -76z m255 61 c0 -9 -9 -15 -25 -15 -24 0 -25 -2 -25
-70 0 -56 -3 -70 -15 -70 -12 0 -15 14 -15 70 0 68 -1 70 -25 70 -16 0 -25 6
-25 15 0 12 14 15 65 15 51 0 65 -3 65 -15z m212 -53 c42 -103 42 -102 25
-102 -9 0 -18 8 -21 18 -9 27 -70 28 -89 1 -8 -11 -22 -19 -30 -17 -11 2 -7
18 19 73 18 39 33 76 33 83 1 6 9 12 19 12 12 0 25 -20 44 -68z m400 0 c15
-37 30 -75 34 -84 9 -25 -18 -23 -34 2 -8 13 -23 20 -42 20 -19 0 -34 -7 -42
-20 -7 -11 -20 -20 -29 -20 -14 0 -11 14 19 85 44 106 57 109 94 17z m258 53
c0 -9 -9 -15 -25 -15 -24 0 -25 -2 -25 -70 0 -56 -3 -70 -15 -70 -12 0 -15 14
-15 70 0 68 -1 70 -25 70 -16 0 -25 6 -25 15 0 12 14 15 65 15 51 0 65 -3 65
-15z m281 -22 c20 -33 24 -35 33 -19 46 78 71 71 33 -8 -15 -30 -27 -66 -27
-80 0 -16 -6 -26 -15 -26 -9 0 -15 9 -15 25 0 14 -11 48 -25 75 -14 27 -25 54
-25 60 0 21 19 9 41 -27z m313 26 c52 -24 58 -112 11 -143 -78 -51 -168 33
-124 115 18 35 69 47 113 28z m186 -43 c0 -69 7 -86 34 -86 35 0 46 20 46 82
0 45 3 58 15 58 25 0 21 -124 -5 -150 -24 -24 -77 -26 -102 -3 -24 22 -27 153
-3 153 11 0 15 -13 15 -54z m338 38 c25 -17 30 -62 10 -94 -10 -15 -10 -22 0
-32 16 -16 15 -28 -3 -28 -8 0 -22 11 -31 25 -9 14 -25 25 -35 25 -14 0 -19
-7 -19 -25 0 -16 -6 -25 -15 -25 -12 0 -15 16 -15 85 l0 85 43 0 c23 0 53 -7
65 -16z m397 1 c0 -5 -17 -11 -37 -13 -33 -3 -38 -7 -38 -28 0 -21 5 -24 36
-24 45 0 42 -24 -4 -28 -28 -3 -32 -7 -32 -33 0 -21 -5 -29 -17 -29 -16 0 -18
10 -17 78 1 42 2 81 3 85 1 12 106 4 106 -8z m136 5 c0 -5 0 -43 1 -82 0 -59
-3 -74 -16 -76 -14 -3 -16 8 -16 76 0 43 3 82 7 85 10 10 23 8 24 -3z m196
-47 l38 -57 3 57 c2 42 7 57 17 57 12 0 15 -17 15 -85 0 -74 -2 -85 -17 -85
-10 0 -34 23 -53 52 l-35 52 -3 -52 c-2 -37 -7 -52 -18 -52 -11 0 -14 17 -14
85 0 105 13 110 67 28z m311 45 c21 -21 13 -29 -23 -22 -27 5 -41 2 -55 -11
-39 -35 -13 -95 41 -95 22 0 28 5 31 25 4 34 22 33 26 -2 5 -34 -19 -53 -65
-53 -64 0 -100 62 -72 124 19 40 90 61 117 34z m255 -3 c1 -5 -17 -11 -40 -13
-34 -3 -43 -7 -43 -22 0 -14 9 -20 37 -22 52 -4 56 -28 4 -28 -34 0 -41 -3
-41 -20 0 -17 7 -20 45 -20 33 0 45 -4 45 -15 0 -12 -13 -15 -60 -15 l-60 0 0
85 0 86 55 -3 c30 -2 56 -7 58 -13z m237 -5 c24 -24 25 -48 4 -78 -14 -21 -14
-25 0 -47 15 -23 15 -25 -2 -25 -10 0 -25 11 -32 25 -18 34 -50 33 -50 -1 0
-19 -4 -25 -17 -22 -15 3 -18 16 -20 73 -1 39 0 76 2 83 7 21 92 15 115 -8z
m390 6 c0 -8 -10 -16 -22 -18 -21 -3 -23 -8 -23 -71 0 -57 -3 -67 -17 -67 -15
0 -18 10 -18 70 0 68 -1 70 -25 70 -16 0 -25 6 -25 15 0 12 14 15 65 15 50 0
65 -3 65 -14z m138 -74 c2 -68 0 -82 -13 -82 -12 0 -15 15 -15 86 0 64 3 85
13 82 8 -3 13 -32 15 -86z m234 71 c22 -20 24 -66 2 -87 -8 -9 -31 -16 -50
-16 -30 0 -34 -3 -34 -25 0 -16 -6 -25 -15 -25 -12 0 -15 15 -15 78 0 43 3 82
7 85 13 14 87 7 105 -10z"/>
<path d="M5278 4364 c-5 -4 -8 -106 -8 -226 l0 -218 100 0 c109 0 136 8 182
57 39 41 58 94 58 163 0 96 -46 175 -119 206 -40 17 -202 31 -213 18z"/>
<path d="M6314 4182 l-50 -127 99 -3 c55 -1 101 -1 103 1 4 4 -85 238 -95 249
-4 4 -29 -50 -57 -120z"/>
<path d="M8078 4267 c-9 -23 -30 -77 -47 -121 -17 -43 -31 -82 -31 -87 0 -5
45 -9 100 -9 55 0 100 2 100 5 0 9 -92 240 -98 247 -4 4 -15 -12 -24 -35z"/>
<path d="M2977 3463 c-4 -3 -7 -28 -7 -55 l0 -48 35 0 c36 0 55 20 55 58 0 35
-60 68 -83 45z"/>
<path d="M3257 3436 c-12 -31 -8 -46 13 -46 23 0 26 18 7 44 -13 18 -14 18
-20 2z"/>
<path d="M3726 3428 c-9 -35 -8 -38 14 -38 11 0 20 3 20 8 0 19 -13 52 -20 52
-4 0 -10 -10 -14 -22z"/>
<path d="M4126 3428 c-9 -35 -8 -38 14 -38 11 0 20 3 20 8 0 19 -13 52 -20 52
-4 0 -10 -10 -14 -22z"/>
<path d="M4957 3452 c-33 -36 -11 -92 36 -92 26 0 57 31 57 57 0 19 -37 53
-58 53 -11 0 -26 -8 -35 -18z"/>
<path d="M5480 3440 c0 -29 2 -31 33 -28 27 2 32 7 32 28 0 21 -5 26 -32 28
-31 3 -33 1 -33 -28z"/>
<path d="M7010 3440 c0 -26 3 -30 29 -30 32 0 48 24 30 46 -6 8 -22 14 -35 14
-20 0 -24 -5 -24 -30z"/>
<path d="M7770 3440 c0 -29 2 -31 33 -28 27 2 32 7 32 28 0 21 -5 26 -32 28
-31 3 -33 1 -33 -28z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
